// $(document).on('turbolinks:load', function() {
$(function() {
	var $trigger = $(".mobile-trigger");
	var $mobileToggle = $(".mobile-toggle");
	var $mobileNavItems = $(".mobile-nav-items");
	var $mobileMenu = $(".mobile-nav");
	var $openSeat = $(".open-seat");
	var $checkContainer = $(".check-container");
	var $interests = $(".interest-section").find("input[type=checkbox]");
	var $coworkingBox = $("#contact_coworking");
	var $contactForm = $("#contact_new_contact");
	var $backendBox = $("#contact_back_interested");
	var $frontBox = $("#contact_front_interested");
	var $mobileBox = $("#contact_mobile_interested");
	var $newsletterBox = $("#contact_newsletter_interested");
	var $learningSection = $(".learning-section");
	var $fallSection = $(".fall-section");
	var $emailField = $("#contact_email");

	console.log("jquery loaded");

	$mobileToggle.on('click', function () {
		console.log("toggle items");
		$mobileNavItems.toggle();
	})

	$(".sliding-link").click(function(e) {
    e.preventDefault();
    var aid = $(this).attr("href");
    $('html,body').animate({scrollTop: $(aid).offset().top},'slow');
	});

	$checkContainer.on('click', 'input', function () {
		console.log("checkbox clicked");
		checkLearningInterest()
	}) 

	$openSeat.hover(function () {
		$(this).css("height", "25");
		$(this).closest(".course").css("paddingBottom", "27px");
		$(this).css("width", "25");
		$(this).css("cursor", "pointer");
		
	}, function () {
		$(this).css("height", "18");
		$(this).closest(".course").css("paddingBottom", "30px");
		$(this).css("width", "18");
		$(this).css("cursor", "pointer");
	});

	$trigger.on('click', function () {
		// need to make this a toggle
		console.log("triggered");
		$mobileMenu.toggle();
	});

	function validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function checkLearningInterest() {
  	if ($frontBox.is(":checked")) {
  		if ($learningSection.is(":hidden")) {
  			$learningSection.show("slow");
  		}
  	} else if ($backendBox.is(":checked")) {
  			if ($learningSection.is(":hidden")) {
  				$learningSection.show("slow");
  			}
  	} else if ($mobileBox.is(":checked")) {
  			if ($learningSection.is(":hidden")) {
  				$learningSection.show("slow");
  			}
  	} else {
  		if ($learningSection.is(":visible")) {
  				$learningSection.hide("slow");
  			}
  	}
  }
	function checkCoworkInterest() {
		var checked = 0
		$interests.each(function () {
			if ($(this).is(":checked")) {
				checked++;
			}
		})
		if ($coworkingBox.is(":checked") && (checked == 1)) {
			console.log("remove learning");
			$learningSection.hide("slow");
		} else {
			$learningSection.show("slow");
		}
		if (checked == 0) {
			$learningSection.hide("slow")
		}
	}



})
